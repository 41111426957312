import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
var compare = function(sortingAttribute) {
    return function(firstElt, secondElt) {
        if (firstElt[sortingAttribute] === null || firstElt[sortingAttribute] === "" || firstElt[sortingAttribute] === undefined || secondElt[sortingAttribute] === null || secondElt[sortingAttribute] === "" || secondElt[sortingAttribute] === undefined) return 0;
        var firstName = firstElt[sortingAttribute].toUpperCase();
        var secondName = secondElt[sortingAttribute].toUpperCase();
        if (firstName < secondName) {
            return -1;
        }
        if (firstName > secondName) {
            return 1;
        }
        return 0;
    };
};
var capitalize = function(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
};
var sortPeopleAlphabetically = function(list, sortingAttribute) {
    var splitSortingAttr = sortingAttribute.toLowerCase().split("_");
    return _to_consumable_array(list).sort(compare("".concat(splitSortingAttr[0]).concat(capitalize(splitSortingAttr[1]))));
};
export default sortPeopleAlphabetically;
