import _define_property from "@swc/helpers/src/_define_property.mjs";
import { Social } from "@lib/types";
export var footerData = [
    {
        title: "Products And Solutions",
        items: [
            {
                title: "Latest Articles",
                href: "https://biocentury.com/latest-articles"
            },
            {
                title: "Products Overview",
                href: "https://biocentury.com/marketing"
            },
            {
                title: "\u7B80\u4F53\u4E2D\u6587",
                href: "https://biocentury.com/marketing/zh-hans"
            },
            {
                title: "\u65E5\u672C\u8A9E",
                href: "https://biocentury.com/marketing/ja"
            },
            {
                title: "\uD55C\uAD6D\uC5B4",
                href: "https://biocentury.com/marketing/ko"
            },
            {
                title: "BCIQ",
                href: "https://bciq.biocentury.com/home"
            },
            {
                title: "Conferences",
                href: "https://biocentury.com/conferences"
            },
            {
                title: "White Papers",
                href: "https://biocentury.com/interactive/whitepapers"
            }, 
        ]
    },
    {
        title: "About Biocentury",
        items: [
            {
                title: "Company Overview",
                href: "https://biocentury.com/about-biocentury"
            },
            {
                title: "Management",
                href: "https://biocentury.com/biocentury-management"
            },
            {
                title: "Editorial & Research",
                href: "https://biocentury.com/about/editorial-research"
            },
            {
                title: "Scientific Advisory Board",
                href: "https://biocentury.com/about/scientific-advisory-board"
            }, 
        ]
    },
    {
        title: "Contact",
        items: [
            {
                title: "Contact Us",
                href: "https://biocentury.com/contact-us"
            },
            {
                title: "Advertising & Sponsorship",
                href: "https://biocentury.com/contact/sponsorship-branding"
            },
            {
                title: "Reprints & Permissions",
                href: "https://biocentury.com/contact/reprints-permissions"
            },
            {
                title: "Career Opportunities",
                href: "https://biocentury.com/contact/career-opportunities"
            },
            {
                title: "Forgot Password",
                href: "https://identity.biocentury.com/user/support/password/forgot"
            }, 
        ]
    },
    {
        title: "Privacy And Legal",
        items: [
            {
                title: "User Agreement",
                href: "https://biocentury.com/legal/user-agreement"
            },
            {
                title: "Terms of Use",
                href: "https://biocentury.com/legal/terms-of-use"
            },
            {
                title: "Privacy Policy",
                href: "https://biocentury.com/legal/privacy-policy"
            },
            {
                title: "Your California Privacy Rights",
                href: "https://biocentury.com/legal/your-california-privacy-rights"
            },
            {
                title: "Use of Images",
                href: "https://biocentury.com/legal/use-of-images"
            },
            {
                title: "Market data",
                href: "https://biocentury.com/market-data"
            }, 
        ]
    }, 
];
var _obj;
export var socialIcons = (_obj = {}, _define_property(_obj, Social.Twitter, "/".concat(Social.Twitter, ".svg")), _define_property(_obj, Social.LinkedIn, "/".concat(Social.LinkedIn, ".svg")), _define_property(_obj, Social.RSS, "/".concat(Social.RSS, ".png")), _define_property(_obj, Social.GLOBE, "/".concat(Social.GLOBE, ".svg")), _obj);
export var socialLinks = [
    {
        title: "Twitter",
        href: "https://twitter.com/BioCentury"
    },
    {
        title: "LinkedIn",
        href: "https://www.linkedin.com/company/biocentury?trk=top_nav_home"
    },
    {
        title: "RSS",
        href: "https://biocentury.com/rssfeeds"
    }, 
];
