import { DateTime } from "luxon";
export var stringToDateTime = function(dateString, timeString, originalTimeZone, selectedTimeZone) {
    if (!dateString || !timeString) {
        return;
    }
    var dateTime = toISOString(dateString, timeString);
    var date = DateTime.fromFormat(dateTime, "yyyy-MM-dd hh:mm", {
        zone: originalTimeZone === null || originalTimeZone === void 0 ? void 0 : originalTimeZone.utcOffset
    });
    if (selectedTimeZone) {
        date.setZone(selectedTimeZone);
    }
    return date;
};
export var datetimeToTZPeriodTime = function(timeString, selectedTimeZone) {
    return (selectedTimeZone === null || selectedTimeZone === void 0 ? void 0 : selectedTimeZone.value) && (timeString === null || timeString === void 0 ? void 0 : timeString.setZone(selectedTimeZone.value).toLocaleString({
        hour12: true,
        hour: "numeric",
        minute: "numeric"
    }));
};
export var datetimeToShortMonthDay = function(timeString) {
    return timeString.toLocaleString({
        month: "short",
        day: "numeric"
    });
};
export var dateToShortDate = function(date) {
    return DateTime.fromISO(date).toLocaleString({
        month: "short",
        day: "numeric"
    });
};
export var toISOString = function(date, time) {
    var ISORegex = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})/g;
    var isLegacy = !!(time === null || time === void 0 ? void 0 : time.match(ISORegex));
    var dateTime = "".concat(time);
    if (!isLegacy) {
        dateTime = time ? "".concat(date, " ").concat(time) : date;
    }
    return dateTime;
};
export var sortEvents = function(list) {
    if (!list) {
        return [];
    }
    return list.sort(function(firstEvent, secondEvent) {
        var firstDate = firstEvent.startDate, firstTime = firstEvent.naiveStartTime;
        var secondDate = secondEvent.startDate, secondTime = secondEvent.naiveStartTime;
        var firstStartsAt = toISOString(firstDate, firstTime);
        var secondStartsAt = toISOString(secondDate, secondTime);
        return new Date(firstStartsAt).valueOf() - new Date(secondStartsAt).valueOf();
    });
};
export var eventsByDate = function(events, originalTimeZone, selectedTimeZone) {
    return sortEvents(events).map(function(event) {
        event.offsettedStartTime = stringToDateTime(event.startDate, event.naiveStartTime, originalTimeZone, selectedTimeZone === null || selectedTimeZone === void 0 ? void 0 : selectedTimeZone.value);
        event.offsettedEndTime = stringToDateTime(event.endDate, event.naiveEndTime, originalTimeZone, selectedTimeZone === null || selectedTimeZone === void 0 ? void 0 : selectedTimeZone.value);
        return event;
    }).reduce(function(sortedEvents, event) {
        var naiveStartTime = event.naiveStartTime, offsettedStartTime = event.offsettedStartTime;
        if (naiveStartTime && offsettedStartTime) {
            var day = datetimeToShortMonthDay(offsettedStartTime);
            if (!sortedEvents[day]) {
                sortedEvents[day] = {
                    timedEvents: [
                        event
                    ],
                    untimedEvents: []
                };
            } else {
                sortedEvents[day].timedEvents.push(event);
            }
            return sortedEvents;
        } else {
            var day1 = dateToShortDate(event.startDate);
            if (!sortedEvents[day1]) {
                sortedEvents[day1] = {
                    timedEvents: [],
                    untimedEvents: [
                        event
                    ]
                };
            } else {
                sortedEvents[day1].untimedEvents.push(event);
            }
            return sortedEvents;
        }
    }, {});
};
