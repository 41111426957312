import { useRef, useEffect } from "react";
import { useLayout } from "@contexts/LayoutContext";
import * as types from "@lib/types";
export var useClick = function(eventId) {
    var elRef = useRef(null);
    var layout = useLayout();
    var executeClick = function() {
        var _a;
        return (_a = elRef === null || elRef === void 0 ? void 0 : elRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    var executeScroll = function() {
        return window.scroll({
            top: elRef.current.offsetTop + elRef.current.getBoundingClientRect().top - 66,
            left: 0,
            behavior: "smooth"
        });
    };
    useEffect(function() {
        if (eventId && (elRef === null || elRef === void 0 ? void 0 : elRef.current)) {
            executeClick();
            if (layout === types.PageType.ConferenceMarketing) {
                executeScroll();
            }
        }
    }, [
        eventId,
        elRef === null || elRef === void 0 ? void 0 : elRef.current
    ]);
    return [
        elRef
    ];
};
