import { Color } from "./types";
var getColorEnum = function(color) {
    if (typeof color === "string") {
        return color;
    } else {
        return color;
    }
};
var buildColorClass = function(param) {
    var type = param.type, colorEnum = param.colorEnum, shadeNumber = param.shadeNumber;
    if (!colorEnum) {
        return "".concat(type, "-").concat(type === "bg" ? "white" : "black");
    }
    if ([
        Color.black,
        Color.white
    ].includes(colorEnum)) {
        return "".concat(type, "-").concat(colorEnum);
    } else {
        return "".concat(type, "-").concat(Color[colorEnum], "-").concat(shadeNumber);
    }
};
var getColorClass = function(type, color, shadeNumber) {
    if (!type) {
        throw new Error("You must define a type.");
    }
    var colorEnum = color ? getColorEnum(color) : undefined;
    return buildColorClass({
        type: type,
        colorEnum: colorEnum,
        shadeNumber: shadeNumber
    });
};
export default getColorClass;
