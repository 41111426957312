import FeaturedArticlesComponent from "./FeaturedArticlesComponent";
import Hero from "./Hero";
import ConferencePerson from "./Person";
import Cta from "./Cta";
import Ribbon from "./Ribbon";
import ConferenceSection from "./Section";
import PeopleComponent from "./PeopleComponent";
import CompaniesComponent from "./CompaniesComponent";
import ScheduleComponent from "./ScheduleComponent";
import TestimonialComponent from "./TestimonialComponent";
import RichtextSection from "./RichtextSection";
import TicketsSection from "./TicketsSection";
import MediaComponent from "./MediaComponent";
import HighlightsComponent from "./HighlightsComponent";
import ConferenceLatestArticlesComponent from "./ConferenceLatestArticlesComponent";
import DescriptionComponent from "./DescriptionComponent";
import { MarkdownComponent } from "./MarkdownComponent";
export default {
    CompaniesComponent: CompaniesComponent,
    ConferencePerson: ConferencePerson,
    ConferenceSection: ConferenceSection,
    ConferenceLatestArticlesComponent: ConferenceLatestArticlesComponent,
    Cta: Cta,
    Hero: Hero,
    MediaComponent: MediaComponent,
    PeopleComponent: PeopleComponent,
    Ribbon: Ribbon,
    RichtextSection: RichtextSection,
    ScheduleComponent: ScheduleComponent,
    TestimonialComponent: TestimonialComponent,
    TicketsSection: TicketsSection,
    HighlightsComponent: HighlightsComponent,
    FeaturedArticlesComponent: FeaturedArticlesComponent,
    DescriptionComponent: DescriptionComponent,
    MarkdownComponent: MarkdownComponent
};
