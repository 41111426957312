export var PageType;
(function(PageType) {
    PageType["ConferenceMarketing"] = "ConferenceMarketing";
    PageType["ConferencePortal"] = "ConferencePortal";
})(PageType || (PageType = {}));
export var MediaType;
(function(MediaType) {
    MediaType["Podcast"] = "Podcast";
    MediaType["Video"] = "Video";
})(MediaType || (MediaType = {}));
export var Social;
(function(Social) {
    Social["Twitter"] = "Twitter";
    Social["LinkedIn"] = "LinkedIn";
    Social["RSS"] = "RSS";
    Social["GLOBE"] = "Globe";
})(Social || (Social = {}));
export var CtaType;
(function(CtaType) {
    CtaType["horizontallyAligned"] = "HORIZONTALLY_ALIGNED";
    CtaType["verticallyAligned"] = "VERTICALLY_ALIGNED";
})(CtaType || (CtaType = {}));
export var LinkType;
(function(LinkType) {
    LinkType["linkedIn"] = "linkedIn";
    LinkType["general"] = "general";
})(LinkType || (LinkType = {}));
export var Color;
(function(Color) {
    Color["gray"] = "gray";
    Color["pink"] = "pink";
    Color["red"] = "red";
    Color["green"] = "green";
    Color["yellow"] = "yellow";
    Color["bcTeal"] = "bc-teal";
    Color["bcMagenta"] = "bc-magenta";
    Color["blue"] = "blue";
    Color["indigo"] = "indigo";
    Color["purple"] = "purple";
    Color["black"] = "black";
    Color["white"] = "white";
})(Color || (Color = {}));
export var ImagePosition;
(function(ImagePosition) {
    ImagePosition["left"] = "left";
    ImagePosition["right"] = "right";
})(ImagePosition || (ImagePosition = {}));
export var TimeZoneOffsetEnum;
(function(TimeZoneOffsetEnum) {
    TimeZoneOffsetEnum["utcMinus1200"] = "UTC\u221212:00";
    TimeZoneOffsetEnum["utcMinus1100"] = "UTC\u221211:00";
    TimeZoneOffsetEnum["utcMinus1000"] = "UTC\u221210:00";
    TimeZoneOffsetEnum["utcMinus0930"] = "UTC\u221209:30";
    TimeZoneOffsetEnum["utcMinus0900"] = "UTC\u221209:00";
    TimeZoneOffsetEnum["utcMinus0800"] = "UTC\u221208:00";
    TimeZoneOffsetEnum["utcMinus0700"] = "UTC\u221207:00";
    TimeZoneOffsetEnum["utcMinus0600"] = "UTC\u221206:00";
    TimeZoneOffsetEnum["utcMinus0500"] = "UTC\u221205:00";
    TimeZoneOffsetEnum["utcMinus0400"] = "UTC\u221204:00";
    TimeZoneOffsetEnum["utcMinus0330"] = "UTC\u221203:30";
    TimeZoneOffsetEnum["utcMinus0300"] = "UTC\u221203:00";
    TimeZoneOffsetEnum["utcMinus0200"] = "UTC\u221202:00";
    TimeZoneOffsetEnum["utcMinus0100"] = "UTC\u221201:00";
    TimeZoneOffsetEnum["utcPlus0000"] = "UTC\xb100:00";
    TimeZoneOffsetEnum["utcPlus0100"] = "UTC+01:00";
    TimeZoneOffsetEnum["utcPlus0200"] = "UTC+02:00";
    TimeZoneOffsetEnum["utcPlus0300"] = "UTC+03:00";
    TimeZoneOffsetEnum["utcPlus0330"] = "UTC+03:30";
    TimeZoneOffsetEnum["utcPlus0400"] = "UTC+04:00";
    TimeZoneOffsetEnum["utcPlus0430"] = "UTC+04:30";
    TimeZoneOffsetEnum["utcPlus0500"] = "UTC+05:00";
    TimeZoneOffsetEnum["utcPlus0530"] = "UTC+05:30";
    TimeZoneOffsetEnum["utcPlus0545"] = "UTC+05:45";
    TimeZoneOffsetEnum["utcPlus0600"] = "UTC+06:00";
    TimeZoneOffsetEnum["utcPlus0630"] = "UTC+06:30";
    TimeZoneOffsetEnum["utcPlus0700"] = "UTC+07:00";
    TimeZoneOffsetEnum["utcPlus0800"] = "UTC+08:00";
    TimeZoneOffsetEnum["utcPlus0845"] = "UTC+08:45";
    TimeZoneOffsetEnum["utcPlus0900"] = "UTC+09:00";
    TimeZoneOffsetEnum["utcPlus0930"] = "UTC+09:30";
    TimeZoneOffsetEnum["utcPlus1000"] = "UTC+10:00";
    TimeZoneOffsetEnum["utcPlus1030"] = "UTC+10:30";
    TimeZoneOffsetEnum["utcPlus1100"] = "UTC+11:00";
    TimeZoneOffsetEnum["utcPlus1200"] = "UTC+12:00";
    TimeZoneOffsetEnum["utcPlus1245"] = "UTC+12:45";
    TimeZoneOffsetEnum["utcPlus1300"] = "UTC+13:00";
    TimeZoneOffsetEnum["utcPlus1400"] = "UTC+14:00";
})(TimeZoneOffsetEnum || (TimeZoneOffsetEnum = {}));
export var SortingAttribute;
(function(SortingAttribute) {
    SortingAttribute["FIRST_NAME"] = "FIRST_NAME";
    SortingAttribute["LAST_NAME"] = "LAST_NAME";
})(SortingAttribute || (SortingAttribute = {}));
export var SortOrder;
(function(SortOrder) {
    SortOrder["NAME_ASC"] = "name_ASC";
    SortOrder["NAME_DESC"] = "name_DESC";
})(SortOrder || (SortOrder = {}));
export var TwitterCard;
(function(TwitterCard) {
    TwitterCard["summary"] = "summary";
    TwitterCard["summary_large_image"] = "summary_large_image";
})(TwitterCard || (TwitterCard = {}));
