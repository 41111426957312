import { useEffect, useState } from "react";
import { getIdentityClientURL } from "@lib/auth";
var useIdentityClientURL = function() {
    var ref = useState(getIdentityClientURL()), redirectURL = ref[0], setRedirectURL = ref[1];
    useEffect(function() {
        var newRedirectURL = getIdentityClientURL(window.location.href);
        setRedirectURL(newRedirectURL);
    }, []);
    return redirectURL;
};
export default useIdentityClientURL;
