export var companyGroups = function(param) {
    var companyGroupId = param.companyGroupId, after = param.after, path = param.path, companyGroupsSortOrder = param.companyGroupsSortOrder, companiesComponentSortOrder = param.companiesComponentSortOrder, preview = param.preview;
    return "/api/companyGroup?pageSize=".concat(process.env.NEXT_PUBLIC_PAGE_SIZE, "&companyGroupId=").concat(companyGroupId, "&after=").concat(after, "&path=").concat(path, "&companyGroupsSortOrder=").concat(companyGroupsSortOrder, "&companiesComponentSortOrder=").concat(companiesComponentSortOrder, "&preview=").concat(preview);
};
export var companiesComponents = function(param) {
    var companiesComponentId = param.companiesComponentId, after = param.after, path = param.path, companiesComponentSortOrder = param.companiesComponentSortOrder, preview = param.preview;
    return "/api/companiesComponent?pageSize=".concat(process.env.NEXT_PUBLIC_PAGE_SIZE, "&companiesComponentId=").concat(companiesComponentId, "&after=").concat(after, "&path=").concat(path, "&companiesComponentSortOrder=").concat(companiesComponentSortOrder, "&preview=").concat(preview);
};
export var companyTombstoneInfo = function(param) {
    var companyId = param.companyId;
    return "/api/companyTombstoneInfo?pageSize=".concat(process.env.NEXT_PUBLIC_PAGE_SIZE, "&companyId=").concat(companyId);
};
export var articles = function(param) {
    var tag = param.tag, before = param.before, path = param.path, preview = param.preview;
    return "/api/article?pageSize=".concat(process.env.NEXT_PUBLIC_PAGE_SIZE, "&permissionTag=").concat(tag, "&before=").concat(before, "&path=").concat(path, "&preview=").concat(preview);
};
export var people = function(param) {
    var peopleComponentId = param.peopleComponentId, after = param.after, path = param.path, preview = param.preview;
    return "/api/people?pageSize=".concat(process.env.NEXT_PUBLIC_PAGE_SIZE, "&peopleComponentId=").concat(peopleComponentId, "&after=").concat(after, "&path=").concat(path, "&preview=").concat(preview);
};
